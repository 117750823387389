<template>
  <div class="Service">
    <div class="Service_head">
      <p>服务商扶持计划</p>
      <p>SERVICE PROVIDER SUPPORT SCHEME</p>
    </div>
    <div class="Service_box">
      <div class="nav_scroll">
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-for="(item, index) in content"
            :key="index"
            class="center"
            :label="item.label"
            :name="String(index)"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="Service_content">
      <el-carousel
        :initial-index="Number(activeName)"
        :autoplay="false"
        height="510px"
        ref="carousel"
        type="card"
        @change="
          (e) => {
            activeName = String(e);
          }
        "
      >
        <el-carousel-item
          v-for="(item, index) in content"
          :key="index"
          :name="String(index)"
        >
          <div class="Service_content_item">
            <img :src="item.image" alt="" />
            <div class="Service_content_item_mai">
              <div class="item_m">
                <div class="item_mai">
                  <img class="mai" :src="item.imag" alt="" />
                  <h2>{{ item.text }}</h2>
                </div>
                <p>
                  {{ item.texte }}
                </p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    activeName(val) {
      this.$nextTick(() => {
        this.$refs.carousel.setActiveItem(val);
      });
    },
  },
  data() {
    return {
      activeName: "0",

      content: [
        {
          label: "高返点",
          name: "third0",
          image: require("@/assets/imgs/channel/jpg_fuchijihua.png"),
          imag: require("@/assets/imgs/channel/gaofandian.png"),
          text: "高返点 ",
          texte:
            "总部提供高额返点优惠辅助服务商更好的发展公司，储备人才，拓展当地市场。",
        },
        {
          label: "产品扶持",
          name: "third1",
          image: require("@/assets/imgs/channel/jpg_chanpinfuchi.png"),
          imag: require("@/assets/imgs/channel/chanpinfuchi.png"),
          text: "产品扶持 ",
          texte:
            "总部为服务商提供最完善的全行业营销解决方案，根据市场需求产品迭代创新速度快，通过一次次快速迭代，使产品赢得用户的广泛青睐，帮助服务商牢牢抓住客户资源。",
        },
        {
          label: "培训扶持",
          name: "third2",
          image: require("@/assets/imgs/channel/jpg_peixunfuchi.png"),
          imag: require("@/assets/imgs/channel/peixunfuchi.png"),
          text: "培训扶持 ",
          texte:
            "总部会定期对服务商人员进行培训，提升专业水品，强化业务技能，帮助服务商更好的开展业务。",
        },
        {
          label: "销售工具",
          name: "third3",
          image: require("@/assets/imgs/channel/jpg_xiaoshougonogju.png"),
          imag: require("@/assets/imgs/channel/xiaoshougongju.png"),
          text: "销售工具 ",
          texte:
            "总部为服务商提供全行业解决方案的同时还提供专业化的crm服务商业务管理系统帮助服务商进行专业化的管理，提高工作效率。",
        },
        {
          label: "资源支持",
          name: "third4",
          image: require("@/assets/imgs/channel/jpg_ziyuanzhichi.png"),
          imag: require("@/assets/imgs/channel/ziyuanzhichi.png"),
          text: "资源支持 ",
          texte:
            "总部通过网络推广得到的资源将全部转交给当地服务商，服务商无需承担推广费用。",
        },
        {
          label: "推广支持",
          name: "third5",
          image: require("@/assets/imgs/channel/jpg_tuiguangzhichi.png"),
          imag: require("@/assets/imgs/channel/tuiguangzhichi.png"),
          text: "推广支持 ",
          texte:
            "总部会根据不同的市场、产品特性不定期的在全国或者当地进行市场推广活动扩大知名度与影响力，包括：u享计划，城市联动会议，会销沙龙等。",
        },
        {
          label: "物料支持",
          name: "third6",
          image: require("@/assets/imgs/channel/jpg_wuliaozhichi.png"),
          imag: require("@/assets/imgs/channel/wuliaozhichi.png"),
          text: "物料支持 ",
          texte:
            "总部定期或者不定期的会根据服务商的情况给予物料支持，利于服务商在当地开展业务。",
        },
        {
          label: "技术支持",
          name: "third7",
          image: require("@/assets/imgs/channel/jpg_jishuzhichi.png"),
          imag: require("@/assets/imgs/channel/jishuzhichi.png"),
          text: "技术支持 ",
          texte:
            "昆仑虚渠道提供7×12小时在线技术服务，保障问题高效、快速的解决，让服务商、客户没有后顾之忧。",
        },
        {
          label: "售后客服",
          name: "third8",
          image: require("@/assets/imgs/channel/jpg_shouhoukefu.png"),
          imag: require("@/assets/imgs/channel/shouhoukefu.png"),
          text: "售后客服 ",
          texte:
            "昆仑虚渠道全方位协助服务商打造售后团队，解决重大紧急问题和活动报备支持，并提供客户运营支持。",
        },
        {
          label: "技术保障",
          name: "third9",
          image: require("@/assets/imgs/channel/jpg_jishubaozhang.png"),
          imag: require("@/assets/imgs/channel/jishubaozhang.png"),
          text: "技术保障 ",
          texte: "强大的研发团队保障系统的安全性、稳定性、可靠性。",
        },
        {
          label: "区域保护",
          name: "third10",
          image: require("@/assets/imgs/channel/jpg_quyubaohu.png"),
          imag: require("@/assets/imgs/channel/quyubaohu.png"),
          text: "区域保护 ",
          texte:
            "总部以县为单位，一个地区只招募一个服务商。对存在服务商的地区开展业务原则上均联合服务商共同开发客户。",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="less">
.Service {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 135px;
}
.Service_head {
  text-align: center;
  p:nth-child(1) {
    font-size: 35px;
    font-weight: bold;
    color: #575961;
    line-height: 70px;
  }
  p:nth-child(2) {
    font-size: 19px;
    font-family: unset;
    font-weight: bold;
    color: #575961;
    margin-bottom: 30px;

  }
}
h1 {
  color: #575961;
  font-size: 40px;
}
.Service_box {
  display: flex;
  justify-content: center;
}
.nav_scroll {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.center {
  display: flex;
  justify-content: center;
}
img {
  width: 100%;
  height: 305px;
}
.Service_content {
  width: 1200px;
  margin: 0 auto;
  height: 510px;
}
.Service_content_item {
  height: 510px;
  color: white;
  width: calc(1200px * 0.74);

}
.Service_content_item img {
  width: calc(1200px * 0.74);
  height: 510px;
  position: relative;
}
.Service_content_item_mai {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.item_m {
  width: 420px;
  height: 200px;
}
.item_mai {
  display: flex;
}
.item_mai h2 {
  color: #575961;
  line-height: 37px;
  margin-left: 10px;
  font-size: 30px;
  font-weight: bold;
}
.item_mai .mai {
  width: 40px;
  height: 40px;
}
.Service_content_item_mai p {
  width: 410px;
  height: 70px;
  color: #575961;
  font-size: 20px;
  line-height: 35px;
  margin-top: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
