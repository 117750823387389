<template>
  <!-- 搜索框-->
  <div class="Search">
    <div class="Search_box">
      <input type="text" placeholder="请输入您所想查询的服务商" />
      <button @click="inquire" class="Search_button">查询</button>
      <img src="@/assets/imgs/channel/sousuo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    inquire() {
      console.log(111);
    },
  },
};
</script>
<style scoped lang="less">
.Search {
  height: 120px;
  margin: 0 auto;
}
.Search_box {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  width: 630px;
  height: 50px;
  background: none;
  outline: none;
  border: 1px solid #0052d9;
  padding-left: 40px;
  font-size: 19px;
  color: #333333;
}
.Search_button {
  width: 90px;
  height: 52px;
  background: #0052d9;
  outline: none;
  font-size: 20px;
  color: #ffffff;
  padding-left: 20px;
}
img {
  position: relative;
  left: -81px;
  top: 0;
}






































</style>
