<template>
  <div>
 <Carousel/>
 <Search/>
 <Service/>
 <Contact/>
  </div>
</template>
<script>
import Carousel from "@/components/pages/cooperation/Carousel.vue";
import Search from "@/components/pages/cooperation/Search.vue";
import Service from "@/components/pages/cooperation/Service.vue";
import Contact from "@/components/pages/cooperation/Contact.vue";
export default {
  components: {
    Carousel,
    Search,
    Service,
    Contact,
  },
  data() {
    return {
     
    };
  },
};
</script>
<style scoped></style>
