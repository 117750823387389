<template>
  <div class="contact">
    <div class="contact_box">
      <div class="top">
        <p>联系我们</p>
        <p>CONTACT US</p>
      </div>
      <div class="contact_left">
        <div class="bok" v-for="(item, index) in content" :key="index">
          <img :src="item.img" alt="" />
          <div class="text">
            <p>{{ item.text }}</p>
            <p>{{ item.texta }}</p>
          </div>
        </div>
      </div>
      <div class="contact_right">
        <img src="@/assets/imgs/channel/map.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/channel/dh.png"),
          text: "公司电话",
          texta: "028 65199065",
        },
        {
          img: require("@/assets/imgs/channel/xx.png"),
          text: "网红孵化邮箱",
          texta: "wh@klx.com",
        },
        {
          img: require("@/assets/imgs/channel/xh.png"),
          text: "品牌合作邮箱",
          texta: "AD@klx.com",
        },
        {
          img: require("@/assets/imgs/channel/zb.png"),
          text: "电商合作邮箱",
          texta: "PM@klx.com",
        },
        {
          img: require("@/assets/imgs/channel/lxr.png"),
          text: "增值业务邮箱",
          texta: "DD@klx.com",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.contact {
  width: 100%;
  height: 650px;
  .contact_box {
    max-width: 880px;
    margin: 0 auto;
    .top {
      text-align: center;
      p:nth-child(1) {
        font-size: 35px;
        font-weight: bold;
        color: #575961;
        line-height: 70px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: bold;
        color: #575961;
        margin-bottom: 60px;
      }
    }
    .contact_left {
      width: 170px;
      height: 389px;
      float: left;
      .bok {
        height: 45px;
        margin-bottom: 45px;
        img {
          float: left;
        }
        .text {
          float: right;
          p:nth-child(1) {
            font-size: 18px;
            font-family: unset;
            font-weight: 600;
            margin-bottom: 9px;
            color: #333333;
          }
          p:nth-child(2) {
            font-size: 17px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .bok:nth-child(5) {
        margin: 0;
      }
      .bok:nth-child(1) {
        margin-top: 10px;
      }
    }
    .contact_right {
      width: 600px;
      height: 389px;
      float: right;
    }
  }
}
</style>
