<template>
  <!-- 轮播图 -->
  <el-carousel class="channel" direction="vertical" :autoplay="false">
    <i class="aftl_top"></i>
    <el-carousel-item
      class="channel_img"
      v-for="(item, inedx) in content"
      :key="inedx"
    >
      <img class="imgl" :src="item.img" alt="" />
      <div class="position">
        <div class="img">
          <p>{{ item.text }}</p>
        </div>
        <div class="laess">
          <div class="content">
            <img src="@/assets/imgs/channel/range.png" alt="" />
            <img src="@/assets/imgs/channel/left.png" alt="" />
            <a href="#"> <button>申请服务商</button></a>
          </div>
        </div>
      </div>
    </el-carousel-item>
    <i class="aftl_bott"></i>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/channel/baner.png"),
          text: "1提供专业产品代销服务，为您的销量保价护行。",
        },
        {
          img: require("@/assets/imgs/channel/baner.png"),
          text: "2提供专业产品代销服务，为您的销量保价护行。",
        },
        {
          img: require("@/assets/imgs/channel/baner.png"),
          text: "3提供专业产品代销服务，为您的销量保价护行。",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.channel,
.channel_img,
.channel_img > .imgl {
  min-width: 1200px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
   min-height: 755px;
}

.aftl_top {
  position: relative;
  left: 30px;
  top: 154px;
  display: inline-block;
  width: 1px;
  height: 113px;
  z-index: 600;
  background: #cccccc;
}
.aftl_bott {
  position: relative;
  left: 30px;
  top: 671px;
  display: inline-block;
  width: 1px;
  height: 113px;
  z-index: 100;
  background: #cccccc;
}
.imgl {
  position: absolute;
}
.position {
  position: relative;
  left: 0px;
  top: 200px;
  margin: 0 auto;
  width: 920px;
  .img {
    width: 920px;
    height: 212px;
    background-image: url("../../../assets/imgs/channel/baneer.png");
    text-align: center;
    padding-top: 100px;
    overflow: hidden;
    p {
      width: 700px;
      margin: 0 auto;
      line-height: 60px;
      font-size: 40px;
      overflow: hidden;
      letter-spacing: 5px;
      font-weight: bold;
      color: white;
    }
  }
  .laess {
    margin: 0 auto;
    margin-top: 157px;
    .content {
      margin: 0 auto;
      width: 506px;
      text-align: center;
      img:nth-child(1) {
        float: left;
        margin-top: 15px;
      }
      img:nth-child(2) {
        float: right;
        margin-top: 15px;
      }
      button {
        width: 150px;
        height: 52px;
        border-radius: 30px;
        font-size: 19px;
        background: #224ede;
        font-family: unset;
        letter-spacing: 2px;
        font-weight: bold;
        color: #ffffff;
        outline: none;
      }
    }
  }
}
</style>



